import React, { useState, useEffect, useReducer } from 'react'
import {
  FormField,
  FormError,
  Copy,
  Button,
  ButtonLink,
} from '../page/components'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SearchIcon from '@material-ui/icons/Search'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox/styled-components'

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_RESET':
      return {
        ...state,
        fetching: false,
        fetched: false,
        data: null,
        error: '',
      }
    case 'FETCH_INIT':
      return {
        ...state,
        fetching: true,
        fetched: false,
        data: null,
        error: '',
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.data,
        error: '',
      }
    case 'FETCH_ERROR':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: null,
        error: action.error,
      }
    default:
      return state
  }
}

const INITIAL_DATA_FETCH_STATE = {
  fetching: false,
  fetched: false,
  data: null,
  error: null,
}

export default ({ setPlan, firebase, country }) => {
  const [hasCoupon, setHasCoupon] = useState(false)
  const [couponText, setCouponText] = useState('')

  const [dataFetch, dispatch] = useReducer(
    dataFetchReducer,
    INITIAL_DATA_FETCH_STATE
  )

  const setCouponValue = val => {
    setPlan(prevState => ({
      ...prevState,
      coupon: val,
    }))
    setCouponText(val)
  }

  useEffect(
    () => {
      if (!hasCoupon) {
        setCouponValue('')
      }
      return () => {}
    },
    [hasCoupon]
  )

  const toggle = e => {
    if (hasCoupon) {
      cancelCoupon(e)
    } else {
      setHasCoupon(true)
    }
  }

  const cancelCoupon = e => {
    e.preventDefault()
    setHasCoupon(false)
    dispatch({ type: 'FETCH_RESET' })
  }

  const validateCoupon = async e => {
    e.preventDefault()
    if (dataFetch.fetching) return

    if (couponText.length > 0) {
      console.log('ⓘ Getting coupon..')
      dispatch({ type: 'FETCH_INIT' })

      const stripeGetSubFunc = firebase.functions.httpsCallable(
        'stripeGetCoupon'
      )
      const funcData = {
        couponId: couponText,
        country: country,
      }

      stripeGetSubFunc(funcData)
        .then(res => {
          console.log('FETCH_SUCCESS', res.data)
          if (
            !res.data.stripe.coupon.max_redemptions ||
            res.data.stripe.coupon.times_redeemed <
              res.data.stripe.coupon.max_redemptions
          ) {
            dispatch({ type: 'FETCH_SUCCESS', data: res.data })
          } else {
            dispatch({
              type: 'FETCH_ERROR',
              error:
                'This promo code has expired or has been redeemed too many times.',
            })
          }
        })
        .catch(error => {
          console.log('FETCH_ERROR', error.message)
          dispatch({ type: 'FETCH_ERROR', error: error.message })
        })
    } else {
      dispatch({
        type: 'FETCH_ERROR',
        error: 'Please enter a valid promo code',
      })
    }
  }

  let inputField, inputResponse, applyText
  if (hasCoupon) {
    let icon = <SearchIcon fontSize="large" />

    applyText = dataFetch.fetching ? `Applying...` : `Apply`

    inputField = (
      <Flex flexWrap={['wrap']} width={`100%`} mb={[2, 2]}>
        <Box width={[1, 6 / 12]}>
          <FieldWrap isActive={dataFetch.fetching}>
            <StyledTextField
              label="Promo code"
              name="coupon"
              margin="normal"
              onChange={e => setCouponValue(e.target.value)}
              value={couponText}
            />
            <ButtonWrap>
              <Button onClick={validateCoupon}>{applyText}</Button>
            </ButtonWrap>
          </FieldWrap>
          {dataFetch.error ? <FormError>{dataFetch.error}</FormError> : null}
        </Box>
      </Flex>
    )

    if (
      dataFetch.fetched &&
      dataFetch.error === '' &&
      dataFetch.data.stripe &&
      dataFetch.data.stripe.coupon
    ) {
      const { metadata } = dataFetch.data.stripe.coupon
      const message =
        metadata && metadata.message ? (
          <span>
            <br />
            {metadata.message}
          </span>
        ) : null

      inputField = (
        <Copy>
          <p>
            Promo applied:
            <PromoText>
              {dataFetch.data.stripe.coupon.name}
              {message}
            </PromoText>
            <PromoCancel onClick={cancelCoupon}>(remove)</PromoCancel>
          </p>
        </Copy>
      )
    }
  }

  return (
    <Wrap>
      <FormField width={`100%`}>
        <StyledFormControlLabelCheckbox
          control={
            <StyledCheckbox
              checked={hasCoupon}
              onChange={e => toggle(e)}
              inputProps={{ 'aria-label': 'Add promo code' }}
            />
          }
          label="Add promo code"
        />
        {inputField}
      </FormField>
    </Wrap>
  )
}

const StyledCheckbox = styled(Checkbox)`
  ${props => props.theme.materialCheckbox(true)};
`

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField('sm')}
`

const StyledCheckButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.8em;
  .MuiIconButton-root {
    color: ${props =>
      props.isValidated
        ? props.theme.colors.highlight1
        : props.theme.colors.secondary};
  }
`

const StyledFormControlLabelCheckbox = styled(FormControlLabel)`
  ${props => props.theme.materialFormControlLabel(false)};
  .MuiFormGroup-root {
    flex-wrap: nowrap;
    flex-direction: row !important;
  }
  flex-wrap: nowrap;
  flex-direction: row !important;
`

const ButtonWrap = styled.div`
  padding-bottom: 10px;
  padding-left: 10px;
`

const FieldWrap = styled.div`
  display: flex;
  align-items: flex-end;
  opacity: ${props => (props.isActive ? `0.5` : `1`)};
`

const Wrap = styled.div`
  margin-bottom: 2.5em;
`

const PromoText = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  font-size: 130%;
  line-height: 130%;
  display: block;
  margin: 0.3em 0;
`

const PromoCancel = styled.button`
  color: ${props => props.theme.colors.greyDark5};
  padding: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.highlight1};
  cursor: pointer;
`
