import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox/styled-components'
import _ from 'lodash'

import {
  FormBox,
  FormFields,
  FormField,
  FormTitle,
  Button,
} from '../page/components'
import SetupCoupon from './setup-coupon'

const Wrap = styled.div`
  width: 100%;
  display: ${props => (props.isActive ? `block` : `none`)};
  opacity: ${props => (props.submitting ? `0.5` : `1`)};
`

const StyledFormControlLabel = styled(FormControlLabel)`
  ${props => props.theme.materialFormControlLabel(true)};
  .MuiFormGroup-root {
    flex-wrap: nowrap;
    flex-direction: row !important;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: bold;
  }
  flex-wrap: nowrap;
  flex-direction: row !important;
`

const StyledRadio = styled(Radio)`
  ${props => props.theme.materialRadio(true)};
`

const StyledSelectFormControl = styled(FormControl)`
  ${props => props.theme.materialFormControlSelectSmall()};
`

const StyledSelect = styled(NativeSelect)`
  ${props => props.theme.materialSelectSmall()};
`

const SetupPlan = ({
  firebase,
  submitting,
  isActive,
  setCurrStep,
  stripe,
  country,
  onChangeCountry,
  currencyOptions,
  isPlanChange,
  planChangeAction,
  selectedDefault,
  handleDataChange,
}) => {
  const currOption = _.find(currencyOptions, { country: country })
  const { plans, currency, symbol } = currOption
  const [plan, setPlan] = useState(selectedDefault || plans[0])

  useEffect(
    () => {
      handleDataChange('plan', plan)
      return () => {}
    },
    [plan]
  )

  let buttonWording, buttonClick, currencySelect, title
  if (isPlanChange && planChangeAction) {
    buttonWording = submitting ? 'Saving...' : 'Save'
    buttonClick = planChangeAction
  } else {
    buttonWording = 'Continue'
    buttonClick = () => {
      setCurrStep(2)
    }
    currencySelect = (
      <ChooseCurrency
        country={country}
        onChangeCountry={onChangeCountry}
        currencyOptions={currencyOptions}
      />
    )
    title = <FormTitle>Payment Option</FormTitle>
  }

  return (
    <Wrap submitting={submitting} isActive={isActive}>
      {title}
      {currencySelect}
      <Form
        country={country}
        plan={plan}
        setPlan={setPlan}
        plans={plans}
        currency={currency}
        symbol={symbol}
      />
      <SetupCoupon
        setPlan={setPlan}
        stripe={stripe}
        firebase={firebase}
        country={country}
      />
      <FormField marginRight={`50%`}>
        <Button type="submit" onClick={buttonClick}>
          {buttonWording}
        </Button>
      </FormField>
    </Wrap>
  )
}

export default SetupPlan

const Form = ({ plan, plans, setPlan, currency, symbol }) => {
  const planValue = plan.value

  const options = plans.map((plan, i) => {
    const { name, value } = plan
    return (
      <StyledFormControlLabel
        key={i}
        value={value}
        control={<StyledRadio />}
        label={name}
      />
    )
  })

  const handlePlanChange = e => {
    const { value } = e.target
    let found = null
    for (let index = 0; index < plans.length; index++) {
      if (parseInt(plans[index].value) === parseInt(value)) {
        found = index
      }
    }
    if (found !== null) {
      setPlan(prevState => ({
        ...prevState,
        ...plans[found],
      }))
    }
  }

  const planPrice = parseInt(plan.value) / 100

  return (
    <Formik
      initialValues={{
        plan: planValue,
      }}
      validationSchema={null}
      onSubmit={null}
    >
      {({ handleSubmit }) => (
        <Flex flexWrap="nowrap" width={['100%']}>
          <Box
            width={[1, `calc(100% - 3px)`, `calc(${(6 / 12) * 100}% - 3px)`]}
          >
            <FormBox>
              <form onSubmit={handleSubmit}>
                <FormFields>
                  <FormField width={`100%`}>
                    <Pricing>
                      <FormControl
                        component="fieldset"
                        className="form-control"
                      >
                        <RadioGroup
                          aria-label="plan"
                          name="plan"
                          value={planValue}
                          onChange={handlePlanChange}
                        >
                          {options}
                        </RadioGroup>
                      </FormControl>
                      <Price>
                        <span>
                          {symbol}
                          {planPrice}
                        </span>
                        <Currency>{currency}</Currency>
                      </Price>
                    </Pricing>
                    <Features>
                      <li>Comprehensive guide to your career</li>
                      <li>On-going access to updated course material</li>
                      <li>Noteworthy industry news and information</li>
                      <li>Years of industry knowledge at the ready</li>
                    </Features>
                  </FormField>
                </FormFields>
              </form>
            </FormBox>
          </Box>
        </Flex>
      )}
    </Formik>
  )
}

const ChooseCurrency = ({ country, onChangeCountry, currencyOptions }) => {
  const currentOption = country
    ? _.find(currencyOptions, { country: country })
    : 'US'

  const selectOptions = currencyOptions.map((option, i) => {
    return (
      <option key={i} value={option.currency}>
        {option.currency}
      </option>
    )
  })

  return (
    <Flex flexWrap="nowrap" width={['50%', '25%']} mb={`2em`}>
      <Box width="100%">
        <p>Select currency</p>
        <StyledSelectFormControl>
          <StyledSelect
            name="currency"
            value={currentOption.currency}
            onChange={onChangeCountry}
          >
            {selectOptions}
          </StyledSelect>
        </StyledSelectFormControl>
      </Box>
    </Flex>
  )
}

const Pricing = styled.div`
  background: ${props => props.theme.colors.highlight1};
  font-weight: bold;
  color: ${props => props.theme.colors.greyDark};
  padding: 30px;
  padding-top: 20px;
  margin-bottom: 2em;
  .MuiFormGroup-root {
    flex-direction: row;
  }
`

const Price = styled.div`
  ${props => props.theme.fontSizing(props.theme.font.titleSizes)};
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  margin-top: 30px;
  line-height: 100%;
`

const Currency = styled.span`
  ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
  font-weight: normal;
  position: relative;
  top: -5px;
  display: inline-block;
  margin-left: 5px;
`

const Features = styled.ul`
  margin: 0;
  padding: 0;
  border-top: 1px solid ${props => props.theme.colors.greyDark2};
  li {
    margin: 0;
    padding: 12px 0 12px 0;
    border-bottom: 1px solid ${props => props.theme.colors.greyDark2};
  }
`
