import React from 'react'
import { getCurrencySymbol } from '../../utils/helpers'

export default ({ plan }) => {
  const symbol = getCurrencySymbol(plan.currency)
  const price = plan.amount / 100

  return (
    <p>
      {symbol}
      {price}
      {plan.currency.toUpperCase()} {plan.nickname}
    </p>
  )
}
