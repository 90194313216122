import React, { useState, useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import SetupPlan from './setup-plan'
import { currencyOptions } from '../../utils/siteConfig'
import Loader from '../global/loader'
import PlanMini from './plan-mini'
import { Copy, Errors } from '../page/components'
import _ from 'lodash'

const INITIAL_SUBSCRIPTION_STATE = {
  fetching: false,
  fetched: false,
  data: null,
}

const INITIAL_UPDATE_STATE = {
  updating: false,
  updated: false,
  data: null,
}

export default ({ stripe, user, firebase }) => {
  const [subscription, setSubscription] = useState(INITIAL_SUBSCRIPTION_STATE)
  const [submitting, setSubmitting] = useState(false)
  const [defaultPlan, setDefaultPlan] = useState(null)
  const [newPlan, setNewPlan] = useState(null)
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(INITIAL_UPDATE_STATE)

  const country = user.stripe.country.toUpperCase()
  const formData = {
    user,
  }

  // Get subscription
  useEffect(
    () => {
      let isCancelled = false

      const getSubscription = async () => {
        console.log('ⓘ Getting subscription..')
        setSubscription(prevState => ({
          ...prevState,
          fetched: false,
          fetching: true,
        }))
        const stripeGetSubFunc = firebase.functions.httpsCallable(
          'stripeGetSubscription'
        )
        const funcData = {
          user: user,
          stripe: user.stripe,
        }
        const res = await stripeGetSubFunc(funcData).catch(error => {
          const { message } = error
          if (!isCancelled) {
            setSubscription(prevState => ({
              ...prevState,
              fetched: true,
              fetching: false,
              data: null,
            }))
            setErrors([`We had a problem getting your subcription! ${message}`])
          }
        })

        console.log('✔ Subscription found', res.data.stripe)

        if (!isCancelled) {
          setSubscription(prevState => ({
            ...prevState,
            fetched: true,
            fetching: false,
            data: res.data.stripe,
          }))

          let planChangeDefault
          const currencyPlanConfig = _.find(currencyOptions, {
            country: country,
          })

          if (currencyPlanConfig) {
            const currentPlanOption = _.find(currencyPlanConfig.plans, {
              id: res.data.stripe.plan.id,
            })
            planChangeDefault = currentPlanOption || currencyPlanConfig.plans[0]
          }
          setDefaultPlan(planChangeDefault)
        }
      }

      if (user && firebase && !subscription.fetching && !subscription.fetched) {
        getSubscription()
      }

      return () => {
        if (subscription.fetching) {
          isCancelled = true
        }
      }
    },
    [user, firebase, subscription]
  )

  const handleSave = () => {
    if (!submitting) {
      setSubmitting(true)

      if (newPlan && newPlan.id && newPlan.id !== defaultPlan.id) {
        setStatus(prevState => ({
          ...prevState,
          updating: true,
          updated: false,
        }))
        const stripeUpdateSubFunc = firebase.functions.httpsCallable(
          'stripeUpdateSubscriptionPlan'
        )
        const funcData = {
          user: user,
          stripe: {
            subscription: subscription.data,
            newPlan: newPlan,
          },
        }
        stripeUpdateSubFunc(funcData)
          .then(res => {
            console.log('✔ Subscription updated', res)
            setSubmitting(false)
            setStatus(prevState => ({
              ...prevState,
              updated: true,
              updating: false,
              data: res.data.stripe,
            }))
          })
          .catch(error => {
            const { message } = error
            console.log('✘ Error', message)
            setSubmitting(false)
            setStatus(prevState => ({
              ...prevState,
              updated: false,
              updating: false,
              data: null,
            }))
            setErrors([`We had a problem updating your plan: ${message}`])
          })
      } else {
        setSubmitting(false)
        setStatus(prevState => ({
          ...prevState,
          updating: false,
          updated: false,
          data: null,
        }))
        setErrors([`Please select a different plan to your current plan.`])
      }
    }
  }

  const handleDataChange = (key, value) => {
    setErrors([])
    setNewPlan(value)
  }

  const onChangeCountry = () => {
    return false
  }

  let currPlan, planSelect, newPlanContent
  if (subscription.fetching) {
    currPlan = <Loader />
  } else if (subscription.data && defaultPlan) {
    if (status.updated) {
      currPlan = null
      planSelect = null
      newPlanContent = (
        <>
          <Copy>
            <Section>
              <h3>Your plan has been updated!</h3>
              <PlanMini plan={status.data.plan} />
            </Section>
          </Copy>
        </>
      )
    } else {
      currPlan = (
        <Copy>
          <Section>
            <h3>Current Plan</h3>
            <PlanMini plan={subscription.data.plan} />
          </Section>
        </Copy>
      )
      planSelect = (
        <>
          <Copy>
            <h3>Select a new plan</h3>
            <p>
              Note: Changes to your subscription will results in a proration (a
              charge adjustment). Your new plan will be billed starting at the
              time the change is made. If going from a Monthly to Yearly, you
              will be charged for the year ahead. Similarly if you are going
              from Yearly to Monthly a credit may be applied to your account for
              the time remaining.
              <br />
              <br />
            </p>
          </Copy>
          <SetupPlan
            stripe={stripe}
            submitting={submitting}
            handleDataChange={handleDataChange}
            formData={formData}
            isActive={true}
            onChangeCountry={onChangeCountry}
            country={user.stripe.country.toUpperCase()}
            currencyOptions={currencyOptions}
            isPlanChange={true}
            planChangeAction={handleSave}
            selectedDefault={defaultPlan}
          />
        </>
      )
    }
  }

  let errorContent
  if (errors.length > 0) {
    errorContent = (
      <Flex flexWrap={['wrap']} width={`100%`} mb={[0]}>
        <Box width={[1, 1]}>
          <Errors>
            {errors.map((error, i) => {
              return <p key={`error-${i}`}>{error}</p>
            })}
          </Errors>
        </Box>
      </Flex>
    )
  }

  return (
    <div>
      {currPlan}
      {newPlanContent}
      {planSelect}
      {errorContent}
    </div>
  )
}

const Section = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.greyDark2};
  margin-bottom: 2em;
`
